/* Roboto regular */

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-Regular.eot");
  src:
    url("/assets/fonts/Roboto/Roboto-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Roboto italic */

@font-face {
  font-family: "Roboto";
  src: url("/Roboto/Roboto-Italic.eot");
  src:
    url("/Roboto/Roboto-Italic.eot?#iefix") format("embedded-opentype"),
    url("/Roboto/Roboto-Italic.woff2") format("woff2"),
    url("/Roboto/Roboto-Italic.woff") format("woff"),
    url("/Roboto/Roboto-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

/* Roboto bold */

@font-face {
  font-family: "Roboto";
  src: url("/Roboto/Roboto-Bold.eot");
  src: url("/Roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype");
  font-weight: 700;
  font-style: normal;
}


// Gibson Regular

@font-face {
  font-family: "Gibson";
  src: url("./Gibson/Gibson-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

// Gibson Semibold

@font-face {
  font-family: "Gibson";
  src: url("./Gibson/Gibson-SemiBold.otf");
  font-weight: 600;
  font-style: normal;
}

// Gibson Medium

@font-face {
  font-family: "Gibson";
  src: url("./Gibson/Gibson-Medium.otf");
  font-weight: 500;
  font-style: normal;
}


/* Inter regular */

@font-face {
  font-family: 'Inter';
  src: url('./Inter/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
/* Inter Medium */

@font-face {
  font-family: 'Inter';
  src: url('./Inter/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Inter semibold */

@font-face {
  font-family: 'Inter';
  src: url('./Inter/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* Inter variable font file */
@font-face {
  font-family: 'Inter';
  src: url('./Inter/Inter-VariableFont.ttf') format('truetype-variations');
  font-weight: 100 900;
  font-style: normal italic;
}