/* You can add global styles to this file, and also import other style files */

@import "./assets/fonts/fonts";
@import (less) "~/node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

body {
  margin: 0;
  overflow-x: hidden;
}

.bs-datepicker-body table td.week span {
  color: #ffffff !important;
}

.bs-datepicker-head,
.bs-datepicker-head,
.bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after,
.bs-datepicker-body table td.active-week span:hover {
  background-color: #ffffff !important;
  color: #3f3f75;
}

.bs-datepicker-body table td span.selected {
  background-color: #3f3f75 !important;
}

.bs-datepicker-head button {
  color: #3f3f75;
}

bs-datepicker-container {
  z-index: 10 !important;
}

.datepicker-container .date-input {
  align-items: center !important;
}

.disabled-datepicker .bs-datepicker-container {
  display: none !important;
}

.ng-star-inserted .clear-right button.btn-success {
  border: 1px solid #3f3f75;
  background-color: #ffffff;
  color: #3f3f75;

  &:hover,
  &:focus {
    color: #000;
    background-color: rgba(0, 0, 0, 0.1);
    border: none;
  }
}

div.bs-datepicker {
  border-radius: 10px;
}
button {
  cursor: pointer;

  &:focus{
    outline: 0px;
  }
}
// slider styles
ngx-slider.ngx-slider {
  margin-top: 18px;
  margin-bottom: 12px;
}
ngx-slider.ngx-slider .ngx-slider-bar {
  background: #dfdfe2;
  height: 5px;
}
ngx-slider.ngx-slider .ngx-slider-selection {
  background: #666672;
}
ngx-slider.ngx-slider .ngx-slider-pointer {
  width: 12px;
  height: 12px;
  top: -4px;
  background: #666672;
  bottom: 0;
}
ngx-slider.ngx-slider .ngx-slider-pointer:after {
  display: none;
}
ngx-slider.ngx-slider .ngx-slider-bubble {
  bottom: 14px;
}
ngx-slider.ngx-slider .ngx-slider-tick {
  width: 0;
  height: 0;
}
ngx-slider.ngx-slider .ngx-slider-span.ngx-slider-bubble {
  display: none !important;
}
// ng-select
.ng-select div.ng-select-container {
  border: 1px solid #d6d6d9;
  border-radius: 9px;
  &:focus,
  &:hover {
    outline: none;
    border: 1.5px solid #9090af;
  }
}
.ng-select.ng-select-multiple
  .ng-select-container{
    &:has(.ng-input){
      cursor: text;
    }
    .ng-value-container
    div.ng-value {
    display: flex;
    background: #ececf1;
    border-radius: 8px;
    flex-direction: row-reverse;
  }
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value{
    cursor: pointer;
    span.ng-value-icon.left {
    border-right: none;
  }
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  div.ng-input {
  border-left: 1px solid #dfdfe2;
  padding-left: 6px;
  padding-bottom: 0;
  height: 24px;
  margin-bottom: 3px;
  input{
    cursor: text;
  }
}
.ng-select .ng-clear-wrapper,
.ng-select .ng-arrow-wrapper {
  display: none;
}
span.ng-value-icon.left {
  color: #a1a1a8;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8f8f91;
  padding-left: 8px;
  padding-top: 2px;
}
.ng-select div,
.ng-select input,
.ng-select span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #56565c;
}
.search-dropdown {
  label.dropdown-label {
    color: #8f8f91;
    margin-left: 26px;
  }

  ul.dropdown-list {
    max-height: 150px;
    overflow-y: auto;

    li {
      overflow-wrap: break-word;
    }
  }
}

.product-info {
  .search-dropdown {
    ul.dropdown-list {
      max-height: 234px;
    }
  }
}
// to override PLC slideout screen read only style
#slideout-tax-premium,
#slideout-calc-premium {
  background: #f1f1f2;
  border: 1px solid #d6d6d9;
}
.no-scroll {
  overflow: hidden;
}
.no-scroll-x {
  overflow-x: hidden;
}
.noPointerEvents {
  pointer-events: none;
}
.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #F8F8FA;
  }
  &::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
    background: #C3C4C7;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
  }
}
::ng-deep angular-editor-toolbar button i {
  font-family: FontAwesome !important; 
}
.body-spinner {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 11;
  background-color: transparent;
  backdrop-filter: blur(8px);
}

ngx-avatars.workflow3 {
  width: 40px !important;
  height: 40px !important;
  .avatar-content {
    width: 40px;
    height: 40px;
    padding: 10px 0;
    text-align: center;
    border-radius: 50%;
    text-transform: uppercase;
    font-size: 24px !important;
    font-family: "Roboto", sans-serif !important;
    line-height: 20px !important;
  }
}

.modal-popup-outer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background: rgba(32, 32, 41, .7);
  max-height:100vh;
  overflow-y: auto;
  padding: 50px;

  .modal-popup-container {
      border-radius: 12px;
      background-color: #FFF;
      overflow: hidden;
      position: relative;
      min-width: 300px;
      height: min-content;

      .modal-popup-close-button {
          position: absolute;
          top: 4px;
          right: 8px;
          z-index: 5;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;
          outline: none;
          border: 0;

          &:focus {
            outline: none;
          }
          
          img {
            width: 32px;
            height: 32px;
          }
      }
  }

}